.ext-header-row th {
  position: -webkit-sticky;
  position: sticky;
  top: 43px;
  min-width: 150px; }

.status-label-bottom {
  vertical-align: bottom !important; }

.search-input {
  min-width: 200px;
  width: 30%;
  /* margin-right: 15px;
     width: 30%;
     min-width: 200px;

     input {
         height: 36px;
     }*/ }

.scroll-table-container {
  display: grid;
  overflow: auto;
  height: calc(100vh - 150px);
  width: 100%;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  border-radius: 0 0 5px 5px;
  background-color: white; }
  .scroll-table-container .ui.structured.table {
    border-collapse: separate; }

.cell-sticky {
  position: relative;
  vertical-align: top; }
  .cell-sticky-value {
    position: -webkit-sticky;
    position: sticky;
    top: 85px; }

.ui.table thead th {
  color: #4d4d4d !important;
  font-weight: 500 !important; }

.table-header-cell {
  /* background-color: white;*/ }

.table-row-click:hover {
  cursor: pointer; }

.wider {
  position: relative; }

.table-header-menu {
  background-color: white;
  border: 1px solid #ddd;
  margin: 0 !important; }
  .table-header-menu_title {
    font-weight: 500;
    font-size: 16px;
    color: #4d4d4d;
    padding-right: 24px; }

thead th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 3; }

.table-info {
  margin: 0 !important; }
  .table-info tr td {
    background: white !important; }
  .table-info tr:last-child {
    border-bottom: 1px solid #ddd !important; }
  .table-info td:hover {
    cursor: pointer;
    /* background-color: rgba(0,0,0,.05)!important*/ }
  .table-info tr:hover td {
    background: #f2f2f2 !important; }
  .table-info:after {
    content: '';
    width: 100%;
    height: 1px;
    background: #ddd;
    position: absolute;
    bottom: 0;
    z-index: 20; }

.no-scroll {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 17; }
  .no-scroll-value {
    z-index: 15; }

.facet-input {
  position: relative; }
  .facet-input_period {
    color: #18a8cc; }
    .facet-input_period:hover {
      cursor: pointer; }
    .facet-input_period__disabled {
      color: #999 !important; }
      .facet-input_period__disabled:hover {
        cursor: default; }

.facet-sortable {
  padding-right: 20px !important; }

.facet-sortable .sort-button {
  background: transparent !important; }

.column-status-facet {
  /*min-width: 200px !important;
    width: 200px !important;*/
  font-weight: bold; }

.small-column {
  width: 50px !important;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  top: 1px;
  z-index: 1;
  background-color: white;
  /*label {
        &:before {
            content: "";
            border-color: rgba(0, 0, 0, .4) !important;
        }

        &:after {
            content: "";
            color: #18a8cc !important;
        }
    }*/ }

.sort-button {
  /* height: 16px !important;
    min-height: 10px !important;
    width: 19px !important;
    margin: 0 !important;
    padding: 0 !important;
    background: transparent !important;
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
    font-size: 18px;
    text-align: center;
    overflow: hidden;*/ }

.sort-button .icon {
  /*display: block;
    margin: 0 !important;
    color: #ccc !important;*/ }

.sort-button:not(.sort-button-active):hover .icon {
  /*color: rgb(155, 155, 155) !important;*/ }

.sort-button-active .icon {
  /* color: rgb(100, 100, 100) !important;*/ }

.sort-button-up {
  /* position: absolute;
    top: 2px;
    right: 1px;
    padding-top: 3px !important;
    padding-bottom: 2px !important;*/ }

.sort-button-down {
  /* display: block;
    position: absolute;
    bottom: 1px;
    right: 1px;
    padding-bottom: 4px !important;*/ }

.sort-button-down > i.icon {
  /*margin-top: -4px !important;*/ }

.label-in-popup {
  display: block;
  margin-bottom: 7px; }

.select-facet-values {
  max-height: 20vh;
  overflow-x: hidden;
  margin-top: 10px;
  min-width: 220px;
  font-size: 12px !important;
  display: grid;
  max-width: 400px; }
  .select-facet-values .field {
    margin-bottom: 7px !important; }
  .select-facet-values label {
    font-size: 13px !important; }

.boolean-facet-values {
  max-height: 60vh;
  overflow-x: hidden;
  margin-top: 10px;
  min-width: 220px;
  font-size: 12px !important; }
  .boolean-facet-values .active-value {
    font-weight: bold; }
  .boolean-facet-values div {
    padding: 8px 0 !important; }
    .boolean-facet-values div:hover {
      cursor: pointer; }
    .boolean-facet-values div span {
      font-size: 13px !important; }

.facet {
  display: flex;
  align-items: center;
  width: 100%;
  font-weight: 500 !important;
  color: #4d4d4d !important; }
  .facet-field__wrap {
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-align: left;
    color: #4d4d4d !important; }
    .facet-field__wrap:hover {
      cursor: pointer; }
  .facet-field__disabled {
    color: #999 !important; }
  .facet-field:hover {
    cursor: pointer; }
  .facet-actions {
    margin: 0 4px; }
    .facet-actions button {
      height: 16px !important;
      min-height: 10px !important;
      width: 19px !important;
      margin: 0 !important;
      padding: 0 !important;
      background: transparent !important;
      border-bottom-left-radius: 0 !important;
      border-top-left-radius: 0 !important;
      font-size: 18px;
      text-align: center;
      overflow: hidden; }
      .facet-actions button .icon {
        display: block;
        margin: 0 !important;
        color: #ccc; }
    .facet-actions__filter {
      opacity: 0; }
      .facet-actions__filter_active {
        opacity: 1 !important;
        color: #18a8cc !important; }
        .facet-actions__filter_active button i {
          color: #18a8cc !important; }
      .facet-actions__filter button:hover i {
        color: rgba(34, 36, 38, 0.35) !important; }
    .facet-actions__sort {
      display: flex;
      flex-direction: column;
      min-height: 15px;
      color: #9b9b9b !important; }
  .facet:hover .facet-actions__filter {
    -webkit-transition: opacity 0.8s ease-in-out;
    transition: opacity 0.8s ease-in-out;
    opacity: 1; }

.reset-selected {
  display: flex;
  justify-content: flex-end;
  padding-top: 4px; }
  .reset-selected span {
    color: #18a8cc;
    font-weight: 500 !important; }
    .reset-selected span:hover {
      cursor: pointer; }
    .reset-selected span:after {
      content: '';
      border-bottom: 1px dashed #18a8cc;
      display: block;
      margin-bottom: 4px; }

@font-face {
    font-family: 'icomoon';
    src: url(/static/media/icomoon.61d56dab.eot);
    src: url(/static/media/icomoon.61d56dab.eot#iefix) format('embedded-opentype'),
        url(/static/media/icomoon.3bb8f0fc.ttf) format('truetype'),
        url(/static/media/icomoon.78395606.woff) format('woff'),
        url(/static/media/icomoon.a436c0cf.svg#icomoon) format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^='icon-'],
[class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    position: relative;
    top: 1px;
    display: inline-block;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-1 {
    font-size: 1.5em !important;
}

.icon-2 {
    font-size: 1.8em !important;
}

.icon-9 {
    font-size: 9px !important;
}

.icon-12 {
    font-size: 12px !important;
}

.icon-14 {
    font-size: 14px !important;
}

.icon-16 {
    font-size: 16px !important;
}

.icon-24 {
    font-size: 24px !important;
}

.icon-32 {
    font-size: 32px !important;
}

.icon-40 {
    font-size: 40px !important;
}

.icon-48 {
    font-size: 48px !important;
}

.icon-barcode:before {
    content: "\e900";
    color: #6a7583;
}
.icon-description:before {
    content: "\e901";
    color: #6a7583;
}
.icon-checkbox-empty:before {
    content: "\e902";
}
.icon-checkbox:before {
    content: "\e903";
}
.icon-clear-filter:before {
    content: "\e904";
    color: #676768;
}
.icon-delete:before {
    content: "\e905";
}
.icon-file-download:before {
    content: "\e906";
}
.icon-file-upload:before {
    content: "\e907";
}
.icon-filter-full:before {
    content: "\e908";
}
.icon-filter:before {
    content: "\e909";
}
.icon-multi-checkbox-empty:before {
    content: "\e90a";
}
.icon-multi-checkbox-some-outline:before {
    content: "\e90b";
}
.icon-multi-checkbox:before {
    content: "\e90c";
}
.icon-home:before {
    content: "\e90d";
}

.card-header-panel {
  position: fixed;
  z-index: 10;
  top: 40px;
  width: 100%;
  height: 56px;
  padding: 12px 32px;
  display: flex;
  background-color: #ffffff;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  justify-content: space-between; }
  .card-header-panel_title {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5; }
    .card-header-panel_title button {
      margin-right: 12px !important;
      background: white !important; }

.card-actions-panel {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 8;
  min-height: 40px;
  background-color: #ffffff;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  margin: auto;
  padding: 16px 0;
  display: flex;
  justify-content: center; }
  .card-actions-panel div {
    width: 960px;
    display: flex;
    justify-content: flex-end; }

.card-content {
  width: 100%;
  padding: 120px 0 94px 0;
  display: flex;
  justify-content: center;
  position: relative; }
  .card-content > div {
    display: flex; }
  .card-content .ui.secondary.pointing.menu {
    position: -webkit-sticky;
    position: sticky;
    top: 120px;
    background-color: #f9f9f9 !important;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content; }
    .card-content .ui.secondary.pointing.menu .item {
      color: #4d4d4d !important; }
  .card-content-block {
    background-color: #fff;
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    box-sizing: border-box;
    min-width: 620px;
    position: relative;
    width: 960px;
    display: flex;
    margin: 0 32px;
    flex-direction: column; }
    .card-content-block__full {
      position: fixed !important;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      z-index: 900;
      margin: auto;
      overflow: hidden; }
    .card-content-block:not(:last-child) {
      margin-bottom: 24px; }
    .card-content-block_menu {
      flex-direction: column; }
    .card-content-block_loader {
      top: calc(50vh) !important; }
    .card-content-block_header {
      font-size: 16px;
      font-weight: 500;
      line-height: 1.5;
      padding: 15px 24px 24px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      display: flex;
      justify-content: space-between;
      position: -webkit-sticky;
      position: sticky;
      left: 0;
      top: 0; }
      .card-content-block_header_title {
        display: flex;
        align-items: center; }
        .card-content-block_header_title__ext {
          font-size: 14px;
          padding-left: 12px;
          color: #999 !important; }
      .card-content-block_header_actions {
        display: flex;
        align-items: center; }
        .card-content-block_header_actions button {
          background: transparent !important; }
          .card-content-block_header_actions button:hover {
            background: #e0e1e2 none !important; }
      .card-content-block_header_accordion:hover:not([disabled]) {
        cursor: pointer; }
      .card-content-block_header__disabled {
        color: #999 !important; }
    .card-content-block_open {
      display: block;
      position: relative;
      /*overflow: auto;*/ }
    .card-content-block_full {
      display: block;
      position: relative;
      overflow: auto;
      max-height: calc(100vh - 80px); }
    .card-content-block_close {
      display: none; }

.select-as-semantic {
  position: relative; }
  .select-as-semantic .remove-select-button {
    position: absolute;
    background: white !important;
    right: 1px;
    top: 1px;
    bottom: 1px;
    margin: 0 !important;
    padding: 8px !important; }
  .select-as-semantic select {
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif !important;
    margin: 0 !important;
    outline: 0 !important;
    -webkit-appearance: none !important;
    tap-highlight-color: rgba(255, 255, 255, 0) !important;
    line-height: 1.21428571em !important;
    padding: 0.67857143em 1em !important;
    font-size: 1em !important;
    background: #fff !important;
    border: 1px solid rgba(34, 36, 38, 0.15) !important;
    color: rgba(0, 0, 0, 0.87) !important;
    border-radius: 0.28571429rem !important;
    box-shadow: 0 0 0 0 transparent inset !important;
    -webkit-transition: color 0.1s ease, border-color 0.1s ease !important;
    transition: color 0.1s ease, border-color 0.1s ease !important; }

.selected_dates {
  display: flex; }
  .selected_dates_text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 150px; }
  .selected_dates_color {
    position: relative; }
    .selected_dates_color:before {
      position: absolute;
      left: 12px;
      content: ' ';
      height: 25px;
      width: 2px;
      background-color: #db2828;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg); }
    .selected_dates_color:after {
      position: absolute;
      left: 12px;
      content: ' ';
      height: 25px;
      width: 2px;
      background-color: #db2828;
      -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg); }

.ui.dropdown .menu > .item > .description,
.ui.dropdown > .text > .description {
  max-width: 250px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

.ui.form .field > label {
  color: #4d4d4d !important;
  font-weight: 500 !important; }

.label-disabled {
  color: #999 !important; }

.react-datepicker-popper {
  z-index: 5 !important; }

.react-datepicker-wrapper {
  display: block !important; }

.react-datepicker__input-container {
  width: 100% !important; }

.react-datepicker__close-icon:after {
  padding: 0 !important;
  color: black !important;
  background: transparent !important;
  font-size: 17px !important;
  font-weight: bold !important; }

.input-error {
  background-color: #fff6f6 !important;
  border-color: #e0b4b4 !important;
  color: #9f3a38 !important;
  box-shadow: none !important; }

.ui.input.error > input {
  background-color: #fff6f6 !important;
  border-color: #e0b4b4 !important;
  color: #9f3a38 !important;
  box-shadow: none !important; }

.label-error {
  color: #9f3a38 !important;
  font-size: 0.8rem; }

.bool-radio-button {
  display: flex;
  height: 38px;
  align-items: center; }
  .bool-radio-button .ui.radio.checkbox {
    padding-right: 15px; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */ }

.form-select {
  display: flex;
  white-space: nowrap;
  flex-wrap: nowrap;
  /* &_disabled {
         opacity: .8;
         background: rgba(1, 1, 1, 0.05);
     }*/ }
  .form-select button {
    margin-left: 8px !important; }

.ui.input:hover input,
.react-datepicker-wrapper:hover input {
  border-color: rgba(34, 36, 38, 0.35) !important;
  box-shadow: none; }

.ui.form textarea:hover {
  border-color: rgba(34, 36, 38, 0.35) !important;
  box-shadow: none; }

.custom-checkbox {
  position: relative; }
  .custom-checkbox input {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 100%;
    height: 100%; }
  .custom-checkbox_disabled {
    opacity: 0.4; }

input,
textarea {
  border: none !important;
  box-shadow: inset 0 0 0 1px rgba(34, 36, 38, 0.15), 0 0 0 2px transparent !important; }
  input:focus,
  input .ui.selection.active.dropdown,
  textarea:focus,
  textarea .ui.selection.active.dropdown {
    box-shadow: inset 0 0 0 1px #29abcc, 0 0 0 2px rgba(41, 171, 204, 0.2) !important; }
  input:hover:not(:focus),
  textarea:hover:not(:focus) {
    box-shadow: inset 0 0 0 1px rgba(34, 36, 38, 0.35), 0 0 0 2px transparent !important; }
    input:hover:not(:focus) > .label,
    textarea:hover:not(:focus) > .label {
      box-shadow: inset 0 0 0 1px rgba(34, 36, 38, 0.35), 0 0 0 2px transparent !important; }

.ui.selection.active.dropdown {
  border: none !important;
  box-shadow: inset 0 0 0 1px #29abcc, 0 0 0 2px rgba(41, 171, 204, 0.2) !important; }

.ui.selection.active.dropdown .menu {
  box-shadow: none;
  border-color: rgba(34, 36, 38, 0.15) !important; }

.return_cost_field .row {
  align-items: flex-end !important; }

.flex-container {
  padding: 0;
  list-style: none;
  -ms-box-orient: horizontal;
  display: -moz-flex;
  display: flex;
  flex-wrap: wrap; }

.flex-container-justify {
  display: flex;
  justify-content: space-between;
  position: relative;
  min-height: 55vh; }

.file-item {
  width: 200px;
  margin: 5px;
  height: 97px;
  overflow: hidden;
  border: 1px solid #d8d8d8;
  border-radius: 2px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center; }

.blurring.dimmed.dimmable > :not(.dimmer) {
  -webkit-filter: none !important;
          filter: none !important; }

.file-item-add {
  width: 97px; }

.file-info {
  width: 100%;
  height: 60px;
  padding-left: 64px;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 11px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  line-height: 16px; }

.file-info-red {
  color: red; }

.file-item .image-container {
  cursor: pointer;
  position: absolute;
  left: 5px;
  top: 5px;
  height: 85px !important;
  width: 70px;
  background-size: cover !important; }

.image-container .icon {
  font-size: 76px;
  color: #ccc;
  margin: -12px -10px;
  line-height: 110px !important; }

.file-item:hover .uploaded-image-delete-button,
.file-item:hover .uploaded-image-edit-button,
.file-item:hover .uploaded-image-download-button {
  display: block !important; }

.uploaded-image-download-button {
  position: absolute;
  right: 42px;
  bottom: 8px;
  cursor: pointer;
  display: none !important; }

.uploaded-image-edit-button {
  position: absolute;
  right: 22px;
  bottom: 8px;
  cursor: pointer;
  display: none !important; }

.uploaded-image-delete-button {
  color: #ed5565;
  position: absolute;
  right: 2px;
  bottom: 8px;
  cursor: pointer;
  display: none !important; }

.uploaded-image-add-button {
  color: #d8d8d8;
  cursor: pointer;
  font-size: 30px !important;
  margin: 0 !important;
  height: auto !important;
  height: initial !important; }

.placeholder-image {
  background: url(/assets/img/photo.svg) center no-repeat;
  cursor: pointer; }

.hiddenfileinput {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1; }

.uploader {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.02);
  cursor: pointer;
  display: flex;
  height: 300px;
  justify-content: center;
  outline: 2px dashed #ccc;
  outline-offset: 5px;
  position: relative;
  width: 100%;
  margin-bottom: 20px; }

.dragndrop {
  outline: 2px dashed #f08080;
  color: #f08080; }

.uploader input {
  display: none; }

.uploader img,
.uploader .icon {
  pointer-events: none; }

.uploader,
.uploader .icon {
  -webkit-transition: all 100ms ease-in;
  transition: all 100ms ease-in;
  margin-bottom: 16px !important; }

.uploader .icon {
  color: #eee;
  color: rgba(0, 0, 0, 0.2);
  font-size: 5em; }

.uploader img {
  left: 50%;
  opacity: 0;
  max-height: 100%;
  max-width: 100%;
  position: absolute;
  top: 50%;
  -webkit-transition: all 300ms ease-in;
  transition: all 300ms ease-in;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }

.uploader.loaded img {
  opacity: 1; }

.ui.placeholder.segment {
  min-height: 1rem !important; }

.ui.input input[placeholder]::-webkit-input-placeholder {
  color: #868686; }

.ui.input input[placeholder]::-moz-placeholder {
  color: #868686; }

.ui.input input[placeholder]:-ms-input-placeholder {
  color: #868686; }

.ui.input input[placeholder]::-ms-input-placeholder {
  color: #868686; }

.ui.input input[placeholder]::placeholder {
  color: #868686; }

.no-bottom-margin {
  margin-bottom: 0 !important; }

.no-top-margin {
  margin-top: 0 !important; }

.ui.segment {
  width: 100%; }

.centered-div {
  width: 509px;
  height: 160px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto; }

.login-form-wrapper {
  padding-top: 0 !important;
  width: 509px !important; }

.login-form-wrapper .login-error {
  color: red; }

.login-form-description {
  width: 277px !important;
  border-right: 1px solid #e0e1e2;
  display: flex !important;
  justify-content: center;
  align-items: center; }
  .login-form-description-company {
    font-weight: bold;
    font-size: 18px; }
  .login-form-description img {
    position: absolute;
    left: 0;
    top: -16px; }

.login-form-input-wrapper {
  width: 232px !important; }

.login-form-forgot-pass {
  cursor: pointer;
  font-size: 10.3pt;
  padding-top: 9px;
  padding-bottom: 9px;
  display: inline-block; }

.language-switcher {
  position: absolute;
  right: 82px;
  width: 224px;
  top: 33px; }

.ui.menu {
  border-radius: 0 !important; }

.ui.secondary.pointing.menu {
  background-color: #2a3a4e !important;
  color: rgba(255, 255, 255, 0.8) !important; }
  .ui.secondary.pointing.menu .item {
    color: rgba(255, 255, 255, 0.8) !important;
    font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-stretch: normal;
    font-style: normal;
    text-transform: none;
    font-weight: 400 !important;
    -webkit-transition: none !important;
    transition: none !important; }
    .ui.secondary.pointing.menu .item:hover {
      background-color: rgba(0, 0, 0, 0.06) !important; }
  .ui.secondary.pointing.menu .active {
    border-color: #18a8cc !important;
    font-weight: 400 !important; }

.ui.secondary.menu .item > img:not(.ui) {
  width: 8em !important;
  margin-right: 2em; }

.ui.secondary.menu .dropdown.item > .menu,
.ui.text.menu .dropdown.item > .menu {
  border-radius: 0 !important;
  margin-top: 0 !important; }

.ui.secondary.pointing.menu .item .active.item {
  border-color: transparent !important; }

.ui.secondary.pointing.menu .active.item.dropdown {
  font-weight: normal;
  font-weight: initial; }

.ui.secondary.pointing.menu .item.dropdown.superActive {
  border-color: #18a8cc !important; }

.ui.secondary.menu .header.item {
  padding: 0.5em 1.5em !important; }

.header-support {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%; }
  .header-support_contacts {
    margin-left: 12px;
    padding-right: 12px;
    border-right: 1px solid rgba(255, 255, 255, 0.6); }
    .header-support_contacts a {
      color: #18a8cc; }

.ui.menu .ui.dropdown.item .menu .item:not(.filtered) {
  display: flex !important; }

@charset "UTF-8";
body {
  overflow-x: auto;
  overflow-y: hidden;
  min-width: 1280px !important;
  font-size: 13px;
  background-color: #f9f9f9;
  font-family: 'Ubuntu', sans-serif !important;
  color: #4d4d4d !important; }

i.blue.icon {
  color: #18a8cc !important; }

.margin-bottom-10 {
  margin-bottom: 10px !important; }

.margin-left-8 {
  margin-left: 8px !important; }

.leaflet-container {
  height: 400px !important;
  width: 800px !important; }

.container {
  padding: 55px 15px 0 15px;
  position: relative;
  overflow-y: auto;
  margin-bottom: 30px; }

.Toastify__toast {
  position: relative;
  min-height: 1em;
  margin: 1em 0;
  background: #f8f8f9;
  padding: 0.7em 1em 0.7em 1.5em !important;
  line-height: 1.4285em;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: opacity 0.1s ease, color 0.1s ease, background 0.1s ease, -webkit-box-shadow 0.1s ease;
  -webkit-transition: opacity 0.1s ease, color 0.1s ease, background 0.1s ease, box-shadow 0.1s ease;
  transition: opacity 0.1s ease, color 0.1s ease, background 0.1s ease, box-shadow 0.1s ease;
  border-radius: 0.28571429rem !important;
  box-shadow: 0 0 0 1px rgba(34, 36, 38, 0.22) inset, 0 0 0 0 transparent; }

.Toastify__toast--info {
  box-shadow: 0 0 0 1px #a9d5de inset, 0 0 0 0 transparent !important;
  background-color: #f8ffff !important;
  color: #276f86 !important; }

.Toastify__toast--error {
  box-shadow: 0 0 0 1px #e0b4b4 inset, 0 0 0 0 transparent !important;
  background-color: #fff6f6 !important;
  color: #9f3a38 !important; }

.Toastify__toast--warning {
  box-shadow: 0 0 0 1px #c9ba9b inset, 0 0 0 0 transparent !important;
  background-color: #fffaf3 !important;
  color: #573a08 !important; }

.Toastify__close-button {
  color: #1b1c1d !important; }

.ui.fullscreen.modal,
.ui.fullscreen.scrolling.modal {
  left: 2.5% !important; }

.scrolling.content {
  max-height: 76vh !important;
  overflow: auto; }

.card-modal {
  min-height: 92vh; }
  .card-modal .content {
    padding-bottom: 15px !important;
    border-bottom-left-radius: 0.28571429rem;
    border-bottom-right-radius: 0.28571429rem; }
  .card-modal .actions {
    min-height: 65px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0; }
  .card-modal .ui.negative.message {
    position: absolute;
    bottom: 64px;
    width: 100%; }

.sub_modal {
  min-height: 81vh; }

.ui.dimmer {
  justify-content: center !important; }

.leaflet-container {
  height: 75vh;
  width: 100%; }

@media only screen and (min-width: 1500px) {
  .ui.wider.container {
    width: calc(1400px) !important; } }

.container-margin-top-bottom {
  margin-bottom: 70px;
  position: relative; }
  .container-margin-top-bottom_top15 {
    margin-top: 15px; }

.padding-14 {
  padding: 14px !important; }

.card-columns {
  padding: 8px 0;
  display: flex !important;
  flex-direction: column;
  justify-content: center; }

.shippers-table .ui.celled.table tr td:first-child,
.shippers-table .ui.celled.table tr th:first-child {
  border-left: 1px solid rgba(34, 36, 38, 0.1) !important; }

.ui.form label {
  margin-bottom: 8px;
  font-weight: bold; }

.table-loader {
  margin-top: 58px !important;
  left: 50%;
  top: calc(100vh / 2) !important; }
  .table-loader-big {
    height: calc(100vh - 180px) !important; }
    .table-loader-big .ui.loader {
      position: fixed !important; }

.table-bottom-loader {
  position: -webkit-sticky;
  position: sticky;
  left: calc(100% / 2);
  bottom: 24px; }

.ui.checkbox label {
  font-weight: normal !important; }

.ui.multiple.dropdown > .label {
  font-size: 0.85714286rem !important; }

.dictionary-edit {
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 24px 32px;
  width: 100% !important;
  /*.dropdown {
        transition: width 0ms!important;
    }

    .visible {
        position: sticky !important;
        width: calc(33.3% - 45px) !important;
        transition: width 0ms;
        //top: calc();
    }*/ }
  .dictionary-edit > .field:not(.wide) {
    width: 33.3% !important;
    -webkit-transition: width 0ms;
    transition: width 0ms; }
  .dictionary-edit > .field.wide {
    width: 100% !important; }
  .dictionary-edit > .field {
    margin-bottom: 15px !important;
    padding: 0 15px !important;
    min-height: 79px; }
  .dictionary-edit > .field input[type='text'] {
    width: 100% !important; }

.user-form,
.role-form,
.profile-form {
  width: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column; }

.profileCard {
  padding-bottom: 12px; }
  .profileCard .ui.segment {
    margin: 0 !important;
    height: 100%; }

.tabs-card {
  padding: 24px 32px; }
  .tabs-card .ui.grid > .row:not(:last-child) {
    padding-bottom: 0 !important; }
  .tabs-card .ui.grid > .row > .column .field {
    width: 100%; }
  .tabs-card .ui.segment {
    margin: 0 !important;
    height: 100%; }
  .tabs-card .all-tabs > .ui.menu.tabular {
    white-space: nowrap; }
  .tabs-card .ui.checkbox {
    padding: 4px 0; }
  .tabs-card_history {
    max-height: 500px;
    overflow: auto; }
    .tabs-card_history .ui.grid > .row:nth-child(2n) {
      background: #f9f9f9; }

.ext-tabs-card {
  height: 52vh;
  overflow: auto; }

.add-right-elements {
  display: flex !important;
  justify-content: flex-end;
  align-items: flex-end; }
  .add-right-elements button {
    height: 33px; }

.mini-column .field {
  width: 50% !important; }

.vertical-menu-card {
  display: flex; }
  .vertical-menu-card .ui.vertical.menu {
    margin: 0 12px 0 0 !important;
    flex-grow: 0;
    /*  width: max-content;*/
    /*height: max-content;*/ }
  .vertical-menu-card .shipping-card-content {
    width: 100%; }

.all-tabs .ui.vertical.menu {
  margin: 0 12px 0 0 !important; }

.temperature-fields {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  height: 36px; }
  .temperature-fields label {
    font-size: 0.92857143em;
    color: #4d4d4d !important;
    font-weight: 500 !important; }
  .temperature-fields .field {
    width: 30% !important; }

.grid-card-actions {
  display: flex;
  justify-content: space-between; }

.grid-card-header {
  display: flex;
  align-items: center; }
  .grid-card-header > div:last-child {
    margin-left: 12px; }

.history-who {
  font-size: 12px;
  font-weight: 500;
  word-break: break-word;
  color: #4d4d4d; }

  .history-comment {
  white-space: pre-wrap;
  word-break: break-all; }

.table-edit-field-nart input {
  width: 150px; }

.table-edit-field-quantity input {
  width: 80px; }

.report {
  max-width: 960px;
  display: flex;
  justify-content: center;
  margin: auto;
  flex-direction: column; }
  .report_params {
    width: 100%;
    background-color: white;
    border: 1px solid #e6e6e6;
    padding: 24px;
    border-radius: 8px; }
  .report_table {
    margin-top: 12px;
    margin-bottom: 35px; }
    .report_table .card-content-block {
      margin: 0 !important; }

input::-webkit-calendar-picker-indicator {
  display: none; }

.dnd-not-visible {
  display: none; }

.representation-modal .ui.message {
  margin: 0 !important; }

.ui.disabled.input,
.ui.input:not(.disabled) input[disabled] {
  opacity: 1 !important; }

.ui.disabled.search,
.ui.form .disabled.field,
.ui.form .disabled.fields .field,
.ui.form .field :disabled {
  opacity: 0.8 !important;
  background: rgba(1, 1, 1, 0.05); }

.table-action-buttons {
  white-space: nowrap; }

.change_password {
  width: 100%;
  display: flex;
  justify-content: flex-end; }

.ui .label {
  font-weight: 500 !important;
  color: #4d4d4d; }

.ui.modal .header {
  font-family: 'Ubuntu' !important;
  font-weight: 500 !important;
  color: #4d4d4d !important; }

.unblockScroll {
  cursor: default !important;
  pointer-events: all !important; }

/*
.ui.form input:focus,
.ui.form textarea:focus {
    //box-shadow: inset 0 0 0 1px #29abcc, 0 0 0 2px rgba(41, 171, 204, 0.2) !important;
    border-color: #18a8cc!important;
    //border: none !important;
}

.ui.input,
.ui.dropdown > input,
.react-datepicker__input-container {
    //border: none !important;
    border-radius: 4px !important;
    !* box-shadow: inset 0 0 0 1px #b3b3b3 !important;*!
}

.ui.input.focus > input,
.ui.input > input:focus,
.ui.dropdown.active {
    //box-shadow: inset 0 0 0 1px #29abcc, 0 0 0 2px rgba(41, 171, 204, 0.2) !important;
    border-color: #18a8cc!important;
    //border: none !important;
}


.ui.selection.active.dropdown .menu {
    box-shadow: inset 0 0 0 1px #b3b3b3, 0 2px 4px rgba(0, 0, 0, 0.2) !important;
    border: none !important;
}*/
.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected {
  background-color: #18a8cc !important; }

/*.react-datepicker-wrapper, .react-datepicker__input-container {
    &:focus {
        outline: none;
    }

    &:focus input {
        box-shadow: inset 0 0 0 1px #29abcc, 0 0 0 2px rgba(41, 171, 204, 0.2)!important;
        border: none!important;
    }
}*/
.ui.button {
  font-family: 'Ubuntu' !important;
  font-weight: 500 !important; }

.ui.button.blue,
.ui.primary.button,
.ui.blue.label {
  background-color: #18a8cc !important; }

.ui.toggle.checkbox input:checked ~ .box:before,
.ui.toggle.checkbox input:checked ~ label:before,
.ui.toggle.checkbox input:focus:checked ~ .box:before,
.ui.toggle.checkbox input:focus:checked ~ label:before {
  background-color: #18a8cc !important; }

i.red.icon {
  color: #9a0000 !important; }

.ui.red.button,
.ui.red.label {
  background-color: #9a0000 !important; }

.ui.inverted.red.basic.button:hover,
.ui.inverted.red.basic.buttons .button:hover,
.ui.inverted.red.buttons .basic.button:hover {
  box-shadow: 0 0 0 2px #9a0000 inset !important;
  color: #9a0000 !important; }

i.green.icon {
  color: #1f5d39 !important; }

.ui.green.button,
.ui.green.label {
  background-color: #1f5d39 !important; }

.ui.inverted.green.button,
.ui.inverted.green.buttons .button {
  color: #35af43 !important;
  box-shadow: 0 0 0 2px #35af43 inset !important; }

i.orange.icon {
  color: #e98b0d !important; }

.ui.orange.button,
.ui.orange.label {
  background-color: #e98b0d !important; }

i.purple.icon {
  color: #463082 !important; }

.ui.purple.button,
.ui.purple.label {
  background-color: #463082 !important; }

i.olive.icon {
  color: #7b8e4a !important; }

.ui.olive.button,
.ui.olive.label {
  background-color: #7b8e4a !important; }

i.teal.icon {
  color: #3c948e !important; }

.ui.teal.button,
.ui.teal.label {
  background-color: #3c948e !important; }

i.pink.icon {
  color: #f3a0a6 !important; }

.ui.pink.button,
.ui.pink.label {
  background-color: #f3a0a6 !important; }

.scroll-grid-container {
  display: grid;
  overflow: auto;
  height: calc(100vh - 150px);
  width: 100%;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  grid-template-rows: auto 76px;
  background-color: white;
  border-radius: 0 0 5px 5px; }

tbody {
  color: #4d4d4d !important; }

.grid_small {
  height: calc(100vh - 420px); }

.grid-table {
  position: relative;
  border-collapse: collapse;
  border: none !important; }

.active.content {
  display: table-row !important; }

/*.ui.grid {
    margin-bottom: 1px;
}*/
.ui.table thead th {
  background-color: white !important; }

.grid-action-btn {
  white-space: nowrap; }

/*.ui.table {
    thead {
        th {
            border-bottom: none !important;
        }
    }
}*/
.sticky-header {
  border-top: none !important; }
  .sticky-header th {
    /*min-width: 100px;
        max-width: 100px;*/
    position: sticky !important;
    position: -webkit-sticky !important;
    top: 0;
    z-index: 1;
    border-bottom: 1px solid #ddd !important;
    font-size: 12.5px !important;
    background-attachment: local !important;
    word-break: break-word; }
  .sticky-header .actions-column {
    right: 0;
    min-width: 150px;
    width: 150px; }
  .sticky-header .actions-column:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: -15px;
    width: 15px;
    background: -webkit-gradient(linear, left top, right top, from(rgba(249, 250, 251, 0)), to(#f9fafb));
    background: -webkit-linear-gradient(left, rgba(249, 250, 251, 0) 0%, #f9fafb 100%);
    background: linear-gradient(to right, rgba(249, 250, 251, 0) 0%, #f9fafb 100%); }
  .sticky-header .small-column {
    z-index: 2 !important; }
  .sticky-header th:not(.small-column) {
    padding-left: 0 !important; }
    .sticky-header th:not(.small-column) .input {
      width: 100%; }
      .sticky-header th:not(.small-column) .input input[type='text'] {
        padding-left: 10px !important;
        padding-right: 5px !important; }

.sticky-header th:not(.small-column) .input input,
.sticky-header th:not(.small-column) .input .label {
  border-color: #ccc; }

.grid-header-panel {
  margin: 0 !important;
  border: 1px solid #e6e6e6;
  background-color: white;
  width: 100%; }
  .grid-header-panel .create-button {
    height: 33px;
    min-width: 150px;
    white-space: nowrap;
    margin-right: 10px !important;
    font-size: unset !important; }
  .grid-header-panel .grid-right-elements {
    display: flex !important;
    justify-content: flex-end; }
    .grid-header-panel .grid-right-elements .orange {
      opacity: 0.7; }
  .grid-header-panel .representation {
    display: flex;
    align-items: center;
    flex-grow: 1; }
    .grid-header-panel .representation label {
      font-weight: 500;
      padding-right: 8px;
      color: #4d4d4d !important; }
    .grid-header-panel .representation .field {
      margin: 0 .25em 0 0;
      flex-grow: 1; }

.status-value {
  white-space: nowrap;
  width: 100%; }

.records-counter {
  white-space: nowrap;
  margin-right: 10px;
  font-size: 12px;
  font-weight: 400;
  color: #4d4d4d !important; }

.grid-row > td > div {
  max-height: 150px;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  min-width: inherit; }

.grid-row td,
.grid-row th {
  /*min-width: 100px;
    max-width: 100px;
    box-sizing: content-box;
    border-top: 1px solid #ddd !important;*/
  background-color: white; }

.link-cell {
  color: #18a8cc !important;
  width: 100%; }
  .link-cell:hover {
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    cursor: pointer; }

.dropdown-blue {
  color: #18a8cc !important; }

.grid-row {
  /*.address-cell {
        max-width: 100px;

        .cell-grid {
            width: inherit;
        }
    }*/ }
  .grid-row-selected td {
    background-color: #e3f5f9 !important; }
  .grid-row-marker {
    font-weight: 600 !important; }

.column-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: inherit;
  display: block;
  position: relative; }

.grid-row .checkbox,
.grid-row .checkbox input.hidden + label {
  cursor: default; }

.grid-row:hover td {
  background-color: #f0f0f0 !important; }

.grid-row th {
  min-width: 150px;
  width: 150px;
  position: -webkit-sticky;
  /* for Safari */
  position: sticky;
  right: 0;
  background: #f9fafb;
  padding: 5px 15px;
  border-left: 1px solid #ddd !important; }

.grid-row th button {
  margin: 0 0 3px 0 !important;
  width: 100%; }

.grid-row .ui.fitted.checkbox {
  margin-top: 4.5px; }

.grid-footer {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0; }

.grid-footer-panel {
  background-color: white;
  /*white-space: nowrap;*/
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  left: 0;
  padding: 15px 15px 0 15px !important;
  min-width: 1260px;
  width: calc(100vw - 28px);
  border-top: 1px solid rgba(34, 36, 38, 0.15);
  flex-wrap: nowrap !important;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  z-index: 2;
  min-height: 62px;
  flex-direction: column !important; }
  .grid-footer-panel .row {
    padding-top: 0 !important;
    flex-wrap: nowrap !important; }
  .grid-footer-panel .grid-right-elements {
    text-align: right; }
  .grid-footer-panel .grid-right-elements label {
    font-size: 13px; }
  .grid-footer-panel .footer_actions {
    display: flex;
    flex-wrap: wrap; }
    .grid-footer-panel .footer_actions button {
      margin-bottom: 8px; }
    .grid-footer-panel .footer_actions_button {
      background-color: transparent !important;
      color: #4d4d4d !important;
      font-weight: 500;
      text-transform: uppercase !important; }
      .grid-footer-panel .footer_actions_button:hover {
        background-color: rgba(0, 0, 0, 0.06) !important; }
  .grid-footer-panel .summary-content-block_footer_accordion {
    margin-right: 4px;
    cursor: pointer; }

.grid-marker {
  position: absolute;
  left: 0;
  height: 100%;
  width: 7px !important;
  top: 0;
  background-color: #18A8CC;
  z-index: 10; }

.filter-popup > div {
  text-align: center;
  margin-bottom: 10px !important; }

.from-popup {
  -webkit-filter: none !important;
          filter: none !important;
  min-width: 250px !important;
  max-width: 100% !important;
  position: absolute !important; }
  .from-popup .separator {
    display: flex;
    align-items: center; }

.label-margin {
  margin: 3px !important; }

.cell-edit {
  white-space: nowrap;
  width: 20%; }
  .cell-edit .button {
    margin-left: 8px; }

.color-info-orange {
  background-color: #fcd8c1 !important; }

.color-info-olive {
  background-color: #ebf1c0 !important; }

.color-info-teal {
  background-color: #b5eae7 !important; }

.grid-row.olive td {
  background-color: #ebf1c0; }

.grid-row.orange td {
  background-color: #fcd8c1; }

.grid-row.teal td {
  background-color: #b5eae7; }

.grid-row.pink td {
  background-color: #fbeff2; }

.react-resizable:hover .react-resizable-handle-se {
  opacity: 1 !important; }

.react-resizable-handle-se {
  top: 0;
  /*right: -8px!important;*/
  /* width: 16px!important;*/
  cursor: col-resize !important;
  height: 100% !important;
  z-index: 100 !important;
  position: absolute !important;
  -ms-user-select: none !important;
      user-select: none !important;
  -moz-user-select: none;
  -webkit-user-select: none;
  opacity: 0; }
  .react-resizable-handle-se:active:before {
    content: '';
    top: 2px;
    height: calc(100% - 4px);
    background-color: #ddd;
    /*left: 8px;*/ }
  .react-resizable-handle-se:active:after {
    opacity: 0; }
  .react-resizable-handle-se:before {
    content: '';
    top: 25%;
    width: 1px;
    height: 50%;
    position: absolute;
    -webkit-transition: all linear 100ms;
    transition: all linear 100ms;
    background-color: #ddd;
    left: 7px; }
  .react-resizable-handle-se:after {
    content: '';
    top: 25%;
    width: 1px;
    height: 50%;
    position: absolute;
    -webkit-transition: all linear 100ms;
    transition: all linear 100ms;
    background-color: #ddd;
    left: 9px; }

.react-resizable-handle {
  position: absolute;
  /* width: 10px !important;
     height: 100% !important;*/
  background-image: none !important;
  /* background-repeat: no-repeat; */
  /* background-origin: content-box; */
  /* box-sizing: border-box; */
  /* background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zd…BMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+); */
  /* background-position: bottom right; */
  /* padding: 0 3px 3px 0; */ }

.footer-info {
  display: flex;
  white-space: nowrap;
  padding-bottom: 0.5rem;
  color: #4d4d4d !important; }
  .footer-info-value {
    font-weight: 500;
    padding-left: 8px; }
  .footer-info-close {
    position: absolute;
    top: 0;
    right: -20px; }
    .footer-info-close:hover {
      cursor: pointer; }
  .footer-info div:not(:last-child) {
    padding-right: 24px; }

.footer-info-label {
  display: flex;
  font-weight: bold;
  color: #18a8cc;
  align-items: baseline;
  padding-bottom: 8px; }
  .footer-info-label:hover {
    cursor: pointer; }

.ellipsis-actions-btn {
  height: 23.94px; }
  .ellipsis-actions-btn i {
    vertical-align: text-top; }

.grid-mass-updates {
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-wrap: nowrap; }
  .grid-mass-updates .ui.selection.dropdown {
    white-space: nowrap; }
  .grid-mass-updates .ui.grid {
    width: 100%;
    flex-wrap: nowrap !important; }
  .grid-mass-updates .ui.grid > .row {
    flex-wrap: nowrap; }
  .grid-mass-updates label {
    padding-right: 8px;
    margin: 0 !important;
    font-weight: 500 !important;
    color: #4d4d4d !important; }
  .grid-mass-updates-fields {
    display: inline-flex !important;
    align-items: center; }
    .grid-mass-updates-fields .field {
      margin: 0 !important;
      width: 100%; }
  .grid-mass-updates-save {
    margin-left: 8px !important;
    height: 38px !important; }

.cell-grid {
  display: flex;
  position: relative;
  width: 100% !important; }
  .cell-grid:hover .cell-grid-copy-btn {
    -webkit-transition: opacity 0.8s ease-in-out;
    transition: opacity 0.8s ease-in-out;
    opacity: 1; }
  .cell-grid-value {
    position: relative;
    width: -webkit-min-content;
    width: -moz-min-content;
    width: min-content;
    min-width: 10px;
    min-height: 18px;
    display: flex; }
    .cell-grid-value:hover {
      cursor: pointer; }
    .cell-grid-value_empty {
      width: 50%; }
  .cell-grid-copy-btn {
    opacity: 0;
    padding: 0 8px;
    color: rgba(34, 36, 38, 0.15); }
    .cell-grid-copy-btn :hover {
      cursor: pointer;
      color: rgba(34, 36, 38, 0.35); }
  .cell-grid .ui.loader {
    position: relative !important;
    padding: 0 8px; }

.value-cell {
  padding-left: 0 !important; }

.all-filters-popup {
  min-width: 250px;
  max-width: 100%; }

.all-filters-header {
  display: flex;
  justify-content: space-between;
  align-items: end;
  margin-bottom: 12px; }

.pl_header {
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 32px;
  text-align: center;
  margin-bottom: 32px; }

.pl_search_header {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: #000000;
  opacity: 0.6; }

.pl_form .ui.labeled.input > .label:not(.corner) {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  border: 1px solid #DEDEDF;
  border-right: none;
  width: 36px;
  background: #F9F9F9;
  display: flex;
  align-items: center;
  justify-content: center; }
  .pl_form .ui.labeled.input > .label:not(.corner) i {
    height: inherit !important;
    margin: 0 !important; }

.pl_country {
  color: #18A8CC;
  margin-left: 12px; }

.pl_pdf-view {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 24px;
  /*.react-pdf__Page__canvas {
        max-width: 100%!important;
        max-height: 320px !important;
        width: inherit!important;
        height: inherit!important;
    }*/ }
  .pl_pdf-view .react-pdf__Page {
    border: 1px solid rgba(0, 0, 0, 0.1); }
  .pl_pdf-view:hover {
    cursor: -webkit-zoom-in;
    cursor: zoom-in; }
  .pl_pdf-view_error {
    color: #CB5963;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 24px;
    text-align: center; }

.pl_pdf-view_full {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 24px;
  /*.react-pdf__Page__canvas {
        width: 100%!important;
        max-height: calc(100vh - 50px) !important;
        height: inherit!important;
    }*/ }
  .pl_pdf-view_full .react-pdf__Page {
    border: 1px solid rgba(0, 0, 0, 0.1); }

.pl-country-flag {
  padding-left: 8px; }

.pl_result {
  background-color: white;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  display: flex;
  margin: 24px 0; }
  .pl_result_header {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 23px;
    color: #2A3A4E;
    padding: 15px 23px !important;
    border-bottom: 1px solid #e6e6e6; }
  .pl_result_text {
    width: 70%;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    .pl_result_text_items {
      margin: 24px 0 !important; }
    .pl_result_text_h {
      font-size: 13px;
      line-height: 15px;
      /* identical to box height */
      text-align: right;
      color: #000000;
      opacity: 0.6; }
  .pl_result_btn {
    padding: 24px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .pl_result_btn > .grid {
      width: 100% !important; }
  .pl_result_preview {
    width: 30%; }

.pl-error {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 45px; }
  .pl-error_header {
    font-size: 20px;
    padding-bottom: 8px; }
  .pl-error_text {
    color: #CB5963; }

.modal-info {
  top: 64px !important;
  margin: 0 !important; }

.info-component {
  position: relative;
  width: 100%;
  padding: 12px;
  margin-bottom: 12px; }
  .info-component__green {
    background-color: rgba(31, 93, 57, 0.08);
    border: 1px solid #1f5d39; }
  .info-component__red {
    background-color: rgba(154, 0, 0, 0.08);
    border: 1px solid #9a0000; }
  .info-component_title {
    font-weight: bold;
    display: flex;
    width: 100%;
    justify-content: space-between;
    /*&_arrow {
            position: absolute;
            right: 0;
        }*/ }
    .info-component_title > div {
      display: flex; }
    .info-component_title:hover {
      cursor: pointer; }
  .info-component_content {
    padding-left: 20px;
    padding-top: 8px;
    overflow: hidden;
    max-height: 0;
    height: auto;
    /* -webkit-transition: max-height 4s;
        transition: max-height 4s;*/ }
    .info-component_content__expanded {
      max-height: 100%; }

