@charset "UTF-8";
body {
  overflow-x: auto;
  overflow-y: hidden;
  min-width: 1280px !important;
  font-size: 13px;
  background-color: #f9f9f9;
  font-family: 'Ubuntu', sans-serif !important;
  color: #4d4d4d !important; }

i.blue.icon {
  color: #18a8cc !important; }

.margin-bottom-10 {
  margin-bottom: 10px !important; }

.margin-left-8 {
  margin-left: 8px !important; }

.leaflet-container {
  height: 400px !important;
  width: 800px !important; }

.container {
  padding: 55px 15px 0 15px;
  position: relative;
  overflow-y: auto;
  margin-bottom: 30px; }

.Toastify__toast {
  position: relative;
  min-height: 1em;
  margin: 1em 0;
  background: #f8f8f9;
  padding: 0.7em 1em 0.7em 1.5em !important;
  line-height: 1.4285em;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: opacity 0.1s ease, color 0.1s ease, background 0.1s ease, -webkit-box-shadow 0.1s ease;
  transition: opacity 0.1s ease, color 0.1s ease, background 0.1s ease, -webkit-box-shadow 0.1s ease;
  transition: opacity 0.1s ease, color 0.1s ease, background 0.1s ease, box-shadow 0.1s ease;
  transition: opacity 0.1s ease, color 0.1s ease, background 0.1s ease, box-shadow 0.1s ease, -webkit-box-shadow 0.1s ease;
  border-radius: 0.28571429rem !important;
  -webkit-box-shadow: 0 0 0 1px rgba(34, 36, 38, 0.22) inset, 0 0 0 0 transparent;
  box-shadow: 0 0 0 1px rgba(34, 36, 38, 0.22) inset, 0 0 0 0 transparent; }

.Toastify__toast--info {
  -webkit-box-shadow: 0 0 0 1px #a9d5de inset, 0 0 0 0 transparent !important;
  box-shadow: 0 0 0 1px #a9d5de inset, 0 0 0 0 transparent !important;
  background-color: #f8ffff !important;
  color: #276f86 !important; }

.Toastify__toast--error {
  -webkit-box-shadow: 0 0 0 1px #e0b4b4 inset, 0 0 0 0 transparent !important;
  box-shadow: 0 0 0 1px #e0b4b4 inset, 0 0 0 0 transparent !important;
  background-color: #fff6f6 !important;
  color: #9f3a38 !important; }

.Toastify__toast--warning {
  -webkit-box-shadow: 0 0 0 1px #c9ba9b inset, 0 0 0 0 transparent !important;
  box-shadow: 0 0 0 1px #c9ba9b inset, 0 0 0 0 transparent !important;
  background-color: #fffaf3 !important;
  color: #573a08 !important; }

.Toastify__close-button {
  color: #1b1c1d !important; }

.ui.fullscreen.modal,
.ui.fullscreen.scrolling.modal {
  left: 2.5% !important; }

.scrolling.content {
  max-height: 76vh !important;
  overflow: auto; }

.card-modal {
  min-height: 92vh; }
  .card-modal .content {
    padding-bottom: 15px !important;
    border-bottom-left-radius: 0.28571429rem;
    border-bottom-right-radius: 0.28571429rem; }
  .card-modal .actions {
    min-height: 65px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0; }
  .card-modal .ui.negative.message {
    position: absolute;
    bottom: 64px;
    width: 100%; }

.sub_modal {
  min-height: 81vh; }

.ui.dimmer {
  justify-content: center !important; }

.leaflet-container {
  height: 75vh;
  width: 100%; }

@media only screen and (min-width: 1500px) {
  .ui.wider.container {
    width: calc(1400px) !important; } }

.container-margin-top-bottom {
  margin-bottom: 70px;
  position: relative; }
  .container-margin-top-bottom_top15 {
    margin-top: 15px; }

.padding-14 {
  padding: 14px !important; }

.card-columns {
  padding: 8px 0;
  display: flex !important;
  flex-direction: column;
  justify-content: center; }

.shippers-table .ui.celled.table tr td:first-child,
.shippers-table .ui.celled.table tr th:first-child {
  border-left: 1px solid rgba(34, 36, 38, 0.1) !important; }

.ui.form label {
  margin-bottom: 8px;
  font-weight: bold; }

.table-loader {
  margin-top: 58px !important;
  left: 50%;
  top: calc(100vh / 2) !important; }
  .table-loader-big {
    height: calc(100vh - 180px) !important; }
    .table-loader-big .ui.loader {
      position: fixed !important; }

.table-bottom-loader {
  position: sticky;
  left: calc(100% / 2);
  bottom: 24px; }

.ui.checkbox label {
  font-weight: normal !important; }

.ui.multiple.dropdown > .label {
  font-size: 0.85714286rem !important; }

.dictionary-edit {
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 24px 32px;
  width: 100% !important;
  /*.dropdown {
        transition: width 0ms!important;
    }

    .visible {
        position: sticky !important;
        width: calc(33.3% - 45px) !important;
        transition: width 0ms;
        //top: calc();
    }*/ }
  .dictionary-edit > .field:not(.wide) {
    width: 33.3% !important;
    transition: width 0ms; }
  .dictionary-edit > .field.wide {
    width: 100% !important; }
  .dictionary-edit > .field {
    margin-bottom: 15px !important;
    padding: 0 15px !important;
    min-height: 79px; }
  .dictionary-edit > .field input[type='text'] {
    width: 100% !important; }

.user-form,
.role-form,
.profile-form {
  width: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column; }

.profileCard {
  padding-bottom: 12px; }
  .profileCard .ui.segment {
    margin: 0 !important;
    height: 100%; }

.tabs-card {
  padding: 24px 32px; }
  .tabs-card .ui.grid > .row:not(:last-child) {
    padding-bottom: 0 !important; }
  .tabs-card .ui.grid > .row > .column .field {
    width: 100%; }
  .tabs-card .ui.segment {
    margin: 0 !important;
    height: 100%; }
  .tabs-card .all-tabs > .ui.menu.tabular {
    white-space: nowrap; }
  .tabs-card .ui.checkbox {
    padding: 4px 0; }
  .tabs-card_history {
    max-height: 500px;
    overflow: auto; }
    .tabs-card_history .ui.grid > .row:nth-child(2n) {
      background: #f9f9f9; }

.ext-tabs-card {
  height: 52vh;
  overflow: auto; }

.add-right-elements {
  display: flex !important;
  justify-content: flex-end;
  align-items: flex-end; }
  .add-right-elements button {
    height: 33px; }

.mini-column .field {
  width: 50% !important; }

.vertical-menu-card {
  display: flex; }
  .vertical-menu-card .ui.vertical.menu {
    margin: 0 12px 0 0 !important;
    flex-grow: 0;
    /*  width: max-content;*/
    /*height: max-content;*/ }
  .vertical-menu-card .shipping-card-content {
    width: 100%; }

.all-tabs .ui.vertical.menu {
  margin: 0 12px 0 0 !important; }

.temperature-fields {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  height: 36px; }
  .temperature-fields label {
    font-size: 0.92857143em;
    color: #4d4d4d !important;
    font-weight: 500 !important; }
  .temperature-fields .field {
    width: 30% !important; }

.grid-card-actions {
  display: flex;
  justify-content: space-between; }

.grid-card-header {
  display: flex;
  align-items: center; }
  .grid-card-header > div:last-child {
    margin-left: 12px; }

.history-who {
  font-size: 12px;
  font-weight: 500;
  word-break: break-word;
  color: #4d4d4d; }

  .history-comment {
  white-space: pre-wrap;
  word-break: break-all; }

.table-edit-field-nart input {
  width: 150px; }

.table-edit-field-quantity input {
  width: 80px; }

.report {
  max-width: 960px;
  display: flex;
  justify-content: center;
  margin: auto;
  flex-direction: column; }
  .report_params {
    width: 100%;
    background-color: white;
    border: 1px solid #e6e6e6;
    padding: 24px;
    border-radius: 8px; }
  .report_table {
    margin-top: 12px;
    margin-bottom: 35px; }
    .report_table .card-content-block {
      margin: 0 !important; }

input::-webkit-calendar-picker-indicator {
  display: none; }

.dnd-not-visible {
  display: none; }

.representation-modal .ui.message {
  margin: 0 !important; }

.ui.disabled.input,
.ui.input:not(.disabled) input[disabled] {
  opacity: 1 !important; }

.ui.disabled.search,
.ui.form .disabled.field,
.ui.form .disabled.fields .field,
.ui.form .field :disabled {
  opacity: 0.8 !important;
  background: rgba(1, 1, 1, 0.05); }

.table-action-buttons {
  white-space: nowrap; }

.change_password {
  width: 100%;
  display: flex;
  justify-content: flex-end; }

.ui .label {
  font-weight: 500 !important;
  color: #4d4d4d; }

.ui.modal .header {
  font-family: 'Ubuntu' !important;
  font-weight: 500 !important;
  color: #4d4d4d !important; }

.unblockScroll {
  cursor: default !important;
  pointer-events: all !important; }

/*
.ui.form input:focus,
.ui.form textarea:focus {
    //box-shadow: inset 0 0 0 1px #29abcc, 0 0 0 2px rgba(41, 171, 204, 0.2) !important;
    border-color: #18a8cc!important;
    //border: none !important;
}

.ui.input,
.ui.dropdown > input,
.react-datepicker__input-container {
    //border: none !important;
    border-radius: 4px !important;
    !* box-shadow: inset 0 0 0 1px #b3b3b3 !important;*!
}

.ui.input.focus > input,
.ui.input > input:focus,
.ui.dropdown.active {
    //box-shadow: inset 0 0 0 1px #29abcc, 0 0 0 2px rgba(41, 171, 204, 0.2) !important;
    border-color: #18a8cc!important;
    //border: none !important;
}


.ui.selection.active.dropdown .menu {
    box-shadow: inset 0 0 0 1px #b3b3b3, 0 2px 4px rgba(0, 0, 0, 0.2) !important;
    border: none !important;
}*/
.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected {
  background-color: #18a8cc !important; }

/*.react-datepicker-wrapper, .react-datepicker__input-container {
    &:focus {
        outline: none;
    }

    &:focus input {
        box-shadow: inset 0 0 0 1px #29abcc, 0 0 0 2px rgba(41, 171, 204, 0.2)!important;
        border: none!important;
    }
}*/
.ui.button {
  font-family: 'Ubuntu' !important;
  font-weight: 500 !important; }

.ui.button.blue,
.ui.primary.button,
.ui.blue.label {
  background-color: #18a8cc !important; }

.ui.toggle.checkbox input:checked ~ .box:before,
.ui.toggle.checkbox input:checked ~ label:before,
.ui.toggle.checkbox input:focus:checked ~ .box:before,
.ui.toggle.checkbox input:focus:checked ~ label:before {
  background-color: #18a8cc !important; }

i.red.icon {
  color: #9a0000 !important; }

.ui.red.button,
.ui.red.label {
  background-color: #9a0000 !important; }

.ui.inverted.red.basic.button:hover,
.ui.inverted.red.basic.buttons .button:hover,
.ui.inverted.red.buttons .basic.button:hover {
  box-shadow: 0 0 0 2px #9a0000 inset !important;
  color: #9a0000 !important; }

i.green.icon {
  color: #1f5d39 !important; }

.ui.green.button,
.ui.green.label {
  background-color: #1f5d39 !important; }

.ui.inverted.green.button,
.ui.inverted.green.buttons .button {
  color: #35af43 !important;
  box-shadow: 0 0 0 2px #35af43 inset !important; }

i.orange.icon {
  color: #e98b0d !important; }

.ui.orange.button,
.ui.orange.label {
  background-color: #e98b0d !important; }

i.purple.icon {
  color: #463082 !important; }

.ui.purple.button,
.ui.purple.label {
  background-color: #463082 !important; }

i.olive.icon {
  color: #7b8e4a !important; }

.ui.olive.button,
.ui.olive.label {
  background-color: #7b8e4a !important; }

i.teal.icon {
  color: #3c948e !important; }

.ui.teal.button,
.ui.teal.label {
  background-color: #3c948e !important; }

i.pink.icon {
  color: #f3a0a6 !important; }

.ui.pink.button,
.ui.pink.label {
  background-color: #f3a0a6 !important; }

.scroll-grid-container {
  display: grid;
  overflow: auto;
  height: calc(100vh - 150px);
  width: 100%;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  grid-template-rows: auto 76px;
  background-color: white;
  border-radius: 0 0 5px 5px; }

tbody {
  color: #4d4d4d !important; }

.grid_small {
  height: calc(100vh - 420px); }

.grid-table {
  position: relative;
  border-collapse: collapse;
  border: none !important; }

.active.content {
  display: table-row !important; }

/*.ui.grid {
    margin-bottom: 1px;
}*/
.ui.table thead th {
  background-color: white !important; }

.grid-action-btn {
  white-space: nowrap; }

/*.ui.table {
    thead {
        th {
            border-bottom: none !important;
        }
    }
}*/
.sticky-header {
  border-top: none !important; }
  .sticky-header th {
    /*min-width: 100px;
        max-width: 100px;*/
    position: sticky !important;
    position: -webkit-sticky !important;
    top: 0;
    z-index: 1;
    border-bottom: 1px solid #ddd !important;
    font-size: 12.5px !important;
    background-attachment: local !important;
    word-break: break-word; }
  .sticky-header .actions-column {
    right: 0;
    min-width: 150px;
    width: 150px; }
  .sticky-header .actions-column:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: -15px;
    width: 15px;
    background: linear-gradient(to right, rgba(249, 250, 251, 0) 0%, #f9fafb 100%); }
  .sticky-header .small-column {
    z-index: 2 !important; }
  .sticky-header th:not(.small-column) {
    padding-left: 0 !important; }
    .sticky-header th:not(.small-column) .input {
      width: 100%; }
      .sticky-header th:not(.small-column) .input input[type='text'] {
        padding-left: 10px !important;
        padding-right: 5px !important; }

.sticky-header th:not(.small-column) .input input,
.sticky-header th:not(.small-column) .input .label {
  border-color: #ccc; }

.grid-header-panel {
  margin: 0 !important;
  border: 1px solid #e6e6e6;
  background-color: white;
  width: 100%; }
  .grid-header-panel .create-button {
    height: 33px;
    min-width: 150px;
    white-space: nowrap;
    margin-right: 10px !important;
    font-size: unset !important; }
  .grid-header-panel .grid-right-elements {
    display: flex !important;
    justify-content: flex-end; }
    .grid-header-panel .grid-right-elements .orange {
      opacity: 0.7; }
  .grid-header-panel .representation {
    display: flex;
    align-items: center;
    flex-grow: 1; }
    .grid-header-panel .representation label {
      font-weight: 500;
      padding-right: 8px;
      color: #4d4d4d !important; }
    .grid-header-panel .representation .field {
      margin: 0 .25em 0 0;
      flex-grow: 1; }

.status-value {
  white-space: nowrap;
  width: 100%; }

.records-counter {
  white-space: nowrap;
  margin-right: 10px;
  font-size: 12px;
  font-weight: 400;
  color: #4d4d4d !important; }

.grid-row > td > div {
  max-height: 150px;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  width: max-content;
  min-width: inherit; }

.grid-row td,
.grid-row th {
  /*min-width: 100px;
    max-width: 100px;
    box-sizing: content-box;
    border-top: 1px solid #ddd !important;*/
  background-color: white; }

.link-cell {
  color: #18a8cc !important;
  width: 100%; }
  .link-cell:hover {
    text-decoration-line: underline;
    cursor: pointer; }

.dropdown-blue {
  color: #18a8cc !important; }

.grid-row {
  /*.address-cell {
        max-width: 100px;

        .cell-grid {
            width: inherit;
        }
    }*/ }
  .grid-row-selected td {
    background-color: #e3f5f9 !important; }
  .grid-row-marker {
    font-weight: 600 !important; }

.column-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: inherit;
  display: block;
  position: relative; }

.grid-row .checkbox,
.grid-row .checkbox input.hidden + label {
  cursor: default; }

.grid-row:hover td {
  background-color: #f0f0f0 !important; }

.grid-row th {
  min-width: 150px;
  width: 150px;
  position: -webkit-sticky;
  /* for Safari */
  position: sticky;
  right: 0;
  background: #f9fafb;
  padding: 5px 15px;
  border-left: 1px solid #ddd !important; }

.grid-row th button {
  margin: 0 0 3px 0 !important;
  width: 100%; }

.grid-row .ui.fitted.checkbox {
  margin-top: 4.5px; }

.grid-footer {
  position: sticky;
  bottom: 0; }

.grid-footer-panel {
  background-color: white;
  /*white-space: nowrap;*/
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  left: 0;
  padding: 15px 15px 0 15px !important;
  min-width: 1260px;
  width: calc(100vw - 28px);
  border-top: 1px solid rgba(34, 36, 38, 0.15);
  flex-wrap: nowrap !important;
  height: max-content;
  z-index: 2;
  min-height: 62px;
  flex-direction: column !important; }
  .grid-footer-panel .row {
    padding-top: 0 !important;
    flex-wrap: nowrap !important; }
  .grid-footer-panel .grid-right-elements {
    text-align: right; }
  .grid-footer-panel .grid-right-elements label {
    font-size: 13px; }
  .grid-footer-panel .footer_actions {
    display: flex;
    flex-wrap: wrap; }
    .grid-footer-panel .footer_actions button {
      margin-bottom: 8px; }
    .grid-footer-panel .footer_actions_button {
      background-color: transparent !important;
      color: #4d4d4d !important;
      font-weight: 500;
      text-transform: uppercase !important; }
      .grid-footer-panel .footer_actions_button:hover {
        background-color: rgba(0, 0, 0, 0.06) !important; }
  .grid-footer-panel .summary-content-block_footer_accordion {
    margin-right: 4px;
    cursor: pointer; }

.grid-marker {
  position: absolute;
  left: 0;
  height: 100%;
  width: 7px !important;
  top: 0;
  background-color: #18A8CC;
  z-index: 10; }

.filter-popup > div {
  text-align: center;
  margin-bottom: 10px !important; }

.from-popup {
  filter: none !important;
  min-width: 250px !important;
  max-width: 100% !important;
  position: absolute !important; }
  .from-popup .separator {
    display: flex;
    align-items: center; }

.label-margin {
  margin: 3px !important; }

.cell-edit {
  white-space: nowrap;
  width: 20%; }
  .cell-edit .button {
    margin-left: 8px; }

.color-info-orange {
  background-color: #fcd8c1 !important; }

.color-info-olive {
  background-color: #ebf1c0 !important; }

.color-info-teal {
  background-color: #b5eae7 !important; }

.grid-row.olive td {
  background-color: #ebf1c0; }

.grid-row.orange td {
  background-color: #fcd8c1; }

.grid-row.teal td {
  background-color: #b5eae7; }

.grid-row.pink td {
  background-color: #fbeff2; }

.react-resizable:hover .react-resizable-handle-se {
  opacity: 1 !important; }

.react-resizable-handle-se {
  top: 0;
  /*right: -8px!important;*/
  /* width: 16px!important;*/
  cursor: col-resize !important;
  height: 100% !important;
  z-index: 100 !important;
  position: absolute !important;
  user-select: none !important;
  -moz-user-select: none;
  -webkit-user-select: none;
  opacity: 0; }
  .react-resizable-handle-se:active:before {
    content: '';
    top: 2px;
    height: calc(100% - 4px);
    background-color: #ddd;
    /*left: 8px;*/ }
  .react-resizable-handle-se:active:after {
    opacity: 0; }
  .react-resizable-handle-se:before {
    content: '';
    top: 25%;
    width: 1px;
    height: 50%;
    position: absolute;
    transition: all linear 100ms;
    background-color: #ddd;
    left: 7px; }
  .react-resizable-handle-se:after {
    content: '';
    top: 25%;
    width: 1px;
    height: 50%;
    position: absolute;
    transition: all linear 100ms;
    background-color: #ddd;
    left: 9px; }

.react-resizable-handle {
  position: absolute;
  /* width: 10px !important;
     height: 100% !important;*/
  background-image: none !important;
  /* background-repeat: no-repeat; */
  /* background-origin: content-box; */
  /* box-sizing: border-box; */
  /* background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zd…BMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+); */
  /* background-position: bottom right; */
  /* padding: 0 3px 3px 0; */ }

.footer-info {
  display: flex;
  white-space: nowrap;
  padding-bottom: 0.5rem;
  color: #4d4d4d !important; }
  .footer-info-value {
    font-weight: 500;
    padding-left: 8px; }
  .footer-info-close {
    position: absolute;
    top: 0;
    right: -20px; }
    .footer-info-close:hover {
      cursor: pointer; }
  .footer-info div:not(:last-child) {
    padding-right: 24px; }

.footer-info-label {
  display: flex;
  font-weight: bold;
  color: #18a8cc;
  align-items: baseline;
  padding-bottom: 8px; }
  .footer-info-label:hover {
    cursor: pointer; }

.ellipsis-actions-btn {
  height: 23.94px; }
  .ellipsis-actions-btn i {
    vertical-align: text-top; }

.grid-mass-updates {
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-wrap: nowrap; }
  .grid-mass-updates .ui.selection.dropdown {
    white-space: nowrap; }
  .grid-mass-updates .ui.grid {
    width: 100%;
    flex-wrap: nowrap !important; }
  .grid-mass-updates .ui.grid > .row {
    flex-wrap: nowrap; }
  .grid-mass-updates label {
    padding-right: 8px;
    margin: 0 !important;
    font-weight: 500 !important;
    color: #4d4d4d !important; }
  .grid-mass-updates-fields {
    display: inline-flex !important;
    align-items: center; }
    .grid-mass-updates-fields .field {
      margin: 0 !important;
      width: 100%; }
  .grid-mass-updates-save {
    margin-left: 8px !important;
    height: 38px !important; }

.cell-grid {
  display: flex;
  position: relative;
  width: 100% !important; }
  .cell-grid:hover .cell-grid-copy-btn {
    -webkit-transition: opacity 0.8s ease-in-out;
    -moz-transition: opacity 0.8s ease-in-out;
    -o-transition: opacity 0.8s ease-in-out;
    transition: opacity 0.8s ease-in-out;
    opacity: 1; }
  .cell-grid-value {
    position: relative;
    width: min-content;
    min-width: 10px;
    min-height: 18px;
    display: flex; }
    .cell-grid-value:hover {
      cursor: pointer; }
    .cell-grid-value_empty {
      width: 50%; }
  .cell-grid-copy-btn {
    opacity: 0;
    padding: 0 8px;
    color: rgba(34, 36, 38, 0.15); }
    .cell-grid-copy-btn :hover {
      cursor: pointer;
      color: rgba(34, 36, 38, 0.35); }
  .cell-grid .ui.loader {
    position: relative !important;
    padding: 0 8px; }

.value-cell {
  padding-left: 0 !important; }

.all-filters-popup {
  min-width: 250px;
  max-width: 100%; }

.all-filters-header {
  display: flex;
  justify-content: space-between;
  align-items: end;
  margin-bottom: 12px; }

.pl_header {
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 32px;
  text-align: center;
  margin-bottom: 32px; }

.pl_search_header {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: #000000;
  opacity: 0.6; }

.pl_form .ui.labeled.input > .label:not(.corner) {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  border: 1px solid #DEDEDF;
  border-right: none;
  width: 36px;
  background: #F9F9F9;
  display: flex;
  align-items: center;
  justify-content: center; }
  .pl_form .ui.labeled.input > .label:not(.corner) i {
    height: inherit !important;
    margin: 0 !important; }

.pl_country {
  color: #18A8CC;
  margin-left: 12px; }

.pl_pdf-view {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 24px;
  /*.react-pdf__Page__canvas {
        max-width: 100%!important;
        max-height: 320px !important;
        width: inherit!important;
        height: inherit!important;
    }*/ }
  .pl_pdf-view .react-pdf__Page {
    border: 1px solid rgba(0, 0, 0, 0.1); }
  .pl_pdf-view:hover {
    cursor: zoom-in; }
  .pl_pdf-view_error {
    color: #CB5963;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 24px;
    text-align: center; }

.pl_pdf-view_full {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 24px;
  /*.react-pdf__Page__canvas {
        width: 100%!important;
        max-height: calc(100vh - 50px) !important;
        height: inherit!important;
    }*/ }
  .pl_pdf-view_full .react-pdf__Page {
    border: 1px solid rgba(0, 0, 0, 0.1); }

.pl-country-flag {
  padding-left: 8px; }

.pl_result {
  background-color: white;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  display: flex;
  margin: 24px 0; }
  .pl_result_header {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 23px;
    color: #2A3A4E;
    padding: 15px 23px !important;
    border-bottom: 1px solid #e6e6e6; }
  .pl_result_text {
    width: 70%;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    .pl_result_text_items {
      margin: 24px 0 !important; }
    .pl_result_text_h {
      font-size: 13px;
      line-height: 15px;
      /* identical to box height */
      text-align: right;
      color: #000000;
      opacity: 0.6; }
  .pl_result_btn {
    padding: 24px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .pl_result_btn > .grid {
      width: 100% !important; }
  .pl_result_preview {
    width: 30%; }

.pl-error {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 45px; }
  .pl-error_header {
    font-size: 20px;
    padding-bottom: 8px; }
  .pl-error_text {
    color: #CB5963; }
